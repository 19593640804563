import React from "react"

function LunchMenyStaticObjekt({ lunchratt }) {
  console.log(lunchratt)
  return (
    <div className="my-4 text-sm text-left">
      <div className="flex flex-row items-baseline ">
        <span className="acme uppercase tracking-wide">
          {lunchratt.info.veckodag}
        </span>
      </div>
      <div className="italic">
        <div dangerouslySetInnerHTML={{ __html: lunchratt?.title }}></div>
      </div>
    </div>
  )
}

export default LunchMenyStaticObjekt
