import React from "react"
import MenyObjekt from "./MenyObjekt"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import LunchClient from "./LunchClient"
import { StaticImage } from "gatsby-plugin-image"

function Meny({ mat }) {
  return (
    <div className="mx-auto mb-16">
      <div className="md:w-1/2 mx-auto primary mb-8">
        <div className="px-6 pt-8 pb-6 flex flex-col flex-wrap items-center h-full justify-center">
          <h2 className="text-2xl uppercase acme text-cherry text-center my-2">
            Dagens Lunch - 135kr
          </h2>

          <p className="italic">Mån-fre kl 11-14</p>
          <h3 className="text-2xl uppercase acme text-cherry text-center my-2">
            Special: Köttbullar{" "}
          </h3>
          <p className="italic">Potatispuré, lingon, gräddsås, gurka</p>
        </div>
      </div>
      <div className="secondary max-w-xl mx-auto px-4 text-center">
        <LunchClient />
        <fieldset className="border-cherry border-2 px-6 rounded-md">
          <legend>
            <h2 className="text-xl md:text-2xl uppercase acme text-cherry relative inline-block -mt-6 px-8 bg-transparent">
              Kvällsmeny
            </h2>
          </legend>
          {mat.map((post, i) => {
            return <MenyObjekt key={i} post={post} />
          })}
        </fieldset>
        <div className="py-8">
          <Link to="https://weiq.tech/gapp?seller=6801de65-2340-416c-9981-890b6016f80c">
            <StaticImage
              src="../../images/clemens-weiq-badge-take-away.png"
              alt="Beställ"
            />
          </Link>
        </div>
        <div className="w-1/2 mx-auto md:w-full px-0 md:px-32 mt-12 blend-multiply">
          <StaticImage src="../../images/kottbullar-raster.png" />
        </div>
      </div>
    </div>
  )
}

export default Meny
