import React from "react"

function MenyObjekt({ post }) {
  return (
    <div className="my-4 text-sm text-left">
      <div className="flex flex-row items-baseline ">
        <span className="acme uppercase tracking-wide food-title ">
          {post.title}
        </span>
        <span className="dots ml-2 flex-auto overflow-visible border-dotted border-b-2 border-gray-900"></span>
        <span className="price pl-1 acme font-bold">
          {post.informationMat.pris}
        </span>
      </div>
      <div className="italic">{post.informationMat.beskrivning}</div>
    </div>
  )
}

export default MenyObjekt
