import React from "react"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import Meny from "../components/restaurang/Meny"
import { useStaticQuery, graphql } from "gatsby"

function RestaurangPage() {
  // const mat = data.allWordpressWpMat.edges;
  const data = useStaticQuery(graphql`
    {
      allWpRatt {
        nodes {
          id
          informationMat {
            beskrivning
            pris
          }
          title
        }
      }
    }
  `)
  const mat = data.allWpRatt.nodes
  return (
    <PageLayout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Restaurang"
      />
      <Meny mat={mat} />
    </PageLayout>
  )
}

export default RestaurangPage
