import React from "react";

function LunchMenyObjekt({ lunchratt }) {
  console.log(lunchratt);
  return (
    <div className="my-4 text-sm text-left">
      <div className="flex flex-row items-baseline ">
        <span className="acme uppercase tracking-wide">
          {lunchratt.acf.veckodag[0].label}
        </span>
      </div>
      <div className="italic">
        <div
          dangerouslySetInnerHTML={{ __html: lunchratt.title.rendered }}
        ></div>
      </div>
    </div>
  );
}

export default LunchMenyObjekt;
