import React, { useState, useEffect } from "react"
import LunchMenyObjekt from "./LunchMenyObjekt"
import { useStaticQuery, graphql } from "gatsby"
import LunchMenyStaticObjekt from "./LunchMenyStaticObjekt"
export default function LunchClient() {
  const [lunchContent, setLunchContent] = useState()

  const data = useStaticQuery(graphql`
    {
      allWpLunchratt {
        nodes {
          title
          info {
            veckodag
          }
        }
      }
    }
  `)
  const staticLunchratter = data.allWpLunchratt.nodes

  useEffect(() => {
    // get data from GitHub api
    fetch(`https://olleburl.in/clemens/wp-json/wp/v2/lunchmeny`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setLunchContent(resultData)
      }) // set data for the number of stars
  }, [])
  const lunchratter = lunchContent

  return (
    <div>
      {lunchContent ? (
        <fieldset className="border-cherry border-2 px-6 rounded-md my-8">
          <legend>
            <h2 className="text-xl md:text-2xl uppercase acme text-cherry relative inline-block -mt-6 px-4 bg-transparent">
              Veckans Lunchmeny
            </h2>
          </legend>
          {lunchratter?.map((lunchratt, i) => {
            return <LunchMenyObjekt key={i} lunchratt={lunchratt} />
          })}
        </fieldset>
      ) : (
        <fieldset className="border-cherry border-2 px-6 rounded-md my-8">
          <legend>
            <h2 className="text-xl md:text-2xl uppercase acme text-cherry relative inline-block -mt-6 px-4 bg-transparent">
              Veckans Lunchmeny
            </h2>
          </legend>
          {staticLunchratter.map(staticLunchratt => {
            return (
              <LunchMenyStaticObjekt
                key={staticLunchratt.id}
                lunchratt={staticLunchratt}
              />
            )
          })}
        </fieldset>
      )}
    </div>
  )
}
